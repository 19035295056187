<template>
	<pc-header/>
	
	<div
		style="text-align: center;height: 150px;background: linear-gradient(to right, #c6e2ff, #79bbff);padding-top: 30px;">
		<div style="font-size: 24px;color: #141517;font-weight: 600;margin-bottom: 20px;">Customer Service</div>
		<div>
			<router-link to="/">
				<el-link href="">Home</el-link>
			</router-link>
			
			/
			<span>Customer Service</span>
		</div>
	</div>
	
	<el-tabs class="tabs-container" tab-position="left" style="margin: 80px auto;width: 1300px;" v-model="activeName"
	         @tab-change="handleClick">
		<template v-for="item in articles" :key="item.title">
			<el-tab-pane :label="item.title" :name="item.title?.toString()">
				<div v-html="item.content"></div>
			</el-tab-pane>
		</template>
		
		<router-link to="/pages/orderQuery">
			<el-tab-pane label="Track My Order" name="Track My Order">
			
			</el-tab-pane>
		
		</router-link>
		
		
		<el-tab-pane label="Price List" name="Price List">
			<div>
				To order online,visit <a href="/pages/productList" >Our Store </a> or place your order by calling 1-888-805-6919. Price list require the <a
				href="https://www.adobe.com/products/acrobat/readstep.html" target="_blank">Adobe Acrobat Reader</a>
				<a href="https://adenamontessori.com/ius/pricelist.pdf" target="_blank">
					<img src="https://adenamontessori.com/ius/images/small_pdf_icon.gif"/>
					pricelist.pdf
				</a>
			</div>
		</el-tab-pane>
		<el-tab-pane label="Order Form" name="Order Form">
			<div style="color: rgb(0, 153, 255);">Order Form:</div>
			<div style="margin-top: 20px">
				<div>
					Order Form require the, <a href="https://www.adobe.com/products/acrobat/readstep.html" target="_blank">Adobe
					Acrobat Reader</a>.
				</div>
				
				<div>
					<a href="https://adenamontessori.com/ius/order_form.pdf" target="_blank">
						<img src="https://adenamontessori.com/ius/images/small_pdf_icon.gif"/>
						Order Form.pdf (Click Here to Download Our Order Form )
					</a>
				</div>
			</div>
		</el-tab-pane>
		<el-tab-pane label="Lead Test Report" name="Lead Test Report">
			<div style="color: rgb(0, 153, 255);">
				Lead Test Report
			</div>
			<div>
				
				<div style="margin-top: 20px">
					<a href="https://adenamontessori.com/ius/CPC.pdf" target="_blank">
						<img src="https://adenamontessori.com/ius/images/small_pdf_icon.gif" width="25" height="16" border="0"
						     align="absmiddle">
							 CHILDREN’S PRODUCT CERTIFICAT (CPC)
					</a>
				</div>
				
				<div style="margin-top: 20px">
					<a href="https://adenamontessori.com/ius/LEAD-TEST-REPORT.pdf">
						<img src="https://adenamontessori.com/ius/images/small_pdf_icon.gif" width="25" height="16" border="0"
						     align="absmiddle">
							 LEAD TEST REPORT
					</a>
				</div>
				
				<div style="margin-top: 20px">
					Unique and nature crude wood, saturated with different ideas and concept, not only meet the look and feel of
					the baby, also play a very important role on the cultivation of baby creativity, space logical thinking
					ability and aesthetic ability.
				</div>
				
				<div style="margin-top: 20px">
					the main raw material of our Wood products is beech wood ,fresh raw materials from Germany, as well as
					basswood and top plywood, no formaldehyde.
				</div>
				
				<div style="margin-top: 20px">
					Currently,There are two kinds of AIDS coating used for the product, one is paint, lacquer, varnish, water
					paint. The Second is ink.
				</div>
				
				<div style="margin-top: 20px">
					The EU standard, American standard and GB standard make it clear that the maximum limit of lead （can be
					migrated）in the paint of toys is 90 PPM.
				</div>
				
				<div style="margin-top: 20px">
					Based on American standard ,the total lead content in paint of toys is less than or equal to 600 PPM. But The
					EU ROHS command that the maximum limit of lead in toys material is 1000 PPM.
				</div>
				
				<div style="margin-top: 20px">
					There are no lead contained in our products. Because we made the following efforts:
				</div>
				
				<div style="margin-top: 20px">
					1, Strengthen the study and training, familiar with national safety standards.
				</div>
				<div style="margin-top: 20px">
					2, The pass of raw materials: one is to choose the qualified suppliers who can meet the requirement of the
					importing country providing us special paint in toys materials; Two is to strengthen the inspection of these
					raw materials before they are used in the factory, refused to accept raw materials unqualified or without
					inspection.
				</div>
				<div style="margin-top: 20px">
					3,Attach great importance to the production process management. Choose appropriate production technology;
					Strictly control the production process of chemicals added and dosage; Pay attention to production tools
					management, clean before using, avoid cross contamination; In order to avoid abusing and mixing the paint
					,strengthen the management of paint store ; in order to facilitate traceability,we use batch management of
					paint, and set up using records, .
				</div>
				
				<div style="margin-top: 20px">
					4, Strengthen quality control,pay attention to the details.
				</div>
				
				<div style="margin-top: 20px">
					5, Launch a pilot project to our new products.
				</div>
			</div>
		</el-tab-pane>
		
		
		<el-tab-pane label="Free Catalog Request" name="Free Catalog Request">
			
			<div style="margin-left: 150px;font-size: 30px;margin-bottom: 50px;">
				Free Catalog Request
			</div>
			
			<el-form :model="freeCatelogForm" label-width="100px" size="large" :rules="freeCatelogRule" ref="freeCatelogForm">
				<el-form-item label="Full Name" prop="fullName">
					<el-input style="width: 400px;height: 50px;margin-right: 20px;" v-model="freeCatelogForm.fullName"
					          placeholder="Please input your Full Name"/>
				</el-form-item>
				<el-form-item label="Address" prop="address">
					<el-input style="width: 400px;height: 50px;margin-right: 20px;" v-model="freeCatelogForm.address"
					          placeholder="Please input your address"/>
				</el-form-item>
				<el-form-item label="City" prop="city">
					<el-input style="width: 400px;height: 50px;margin-right: 20px;" v-model="freeCatelogForm.city"
					          placeholder="Please input your city"/>
				</el-form-item>
				<el-form-item label="State" prop="state">
					<el-input style="width: 400px;height: 50px;margin-right: 20px;" v-model="freeCatelogForm.state"
					          placeholder="Please input your state"/>
				</el-form-item>
				<el-form-item label="Zip" prop="zip">
					<el-input style="width: 400px;height: 50px;margin-right: 20px;" v-model="freeCatelogForm.zip"
					          placeholder="Please input your zip"/>
				</el-form-item>
				<el-form-item label="Country" prop="country">
					<el-input style="width: 400px;height: 50px;margin-right: 20px;" v-model="freeCatelogForm.country"
					          placeholder="Please input your country"/>
				</el-form-item>
				<el-form-item label="Tel" prop="tel">
					<el-input style="width: 400px;height: 50px;margin-right: 20px;" v-model="freeCatelogForm.tel"
					          placeholder="Please input your tel"/>
				</el-form-item>
				<el-form-item label="Email" prop="email">
					<el-input style="width: 400px;height: 50px;margin-right: 20px;" v-model="freeCatelogForm.email"
					          placeholder="Please input your email"/>
				</el-form-item>
			
			
			</el-form>
			<el-button @click="submitFreeCatelog" style="margin-left: 180px;" type="primary">
				SUBMIT
			</el-button>
		</el-tab-pane>
		
		<el-tab-pane label="Support Online" name="Support Online">
			<div style="margin-left: 150px;font-size: 30px;margin-bottom: 50px;">
				Support Online
			</div>
			<el-form :model="supportOnlineForm" label-width="100px" size="large" :rules="supportOnlineRule"
			         ref="supportOnlineForm">
				<el-form-item label="Email" prop="email">
					<el-input style="width: 400px;height: 50px;margin-right: 20px;" v-model="supportOnlineForm.email"
					          placeholder="Please input your email"/>
				</el-form-item>
				<el-form-item label="First Name" prop="firstName">
					<el-input style="width: 400px;height: 50px;margin-right: 20px;" v-model="supportOnlineForm.firstName"
					          placeholder="Please input your first name"/>
				</el-form-item>
				<el-form-item label="Last Name" prop="lastName">
					<el-input style="width: 400px;height: 50px;margin-right: 20px;" v-model="supportOnlineForm.lastName"
					          placeholder="Please input your last name"/>
				</el-form-item>
				<el-form-item label="Phone" prop="phone">
					<el-input style="width: 400px;height: 50px;margin-right: 20px;" v-model="supportOnlineForm.phone"
					          placeholder="Please input your phone"/>
				</el-form-item>
				<el-form-item label="Subject" prop="subject">
					<el-input style="width: 400px;height: 50px;margin-right: 20px;" v-model="supportOnlineForm.subject"
					          placeholder="Please input subject"/>
				</el-form-item>
				<el-form-item label="Content" prop="content">
					<el-input type="textarea" style="width: 400px;height: 50px;margin-right: 20px;"
					          v-model="supportOnlineForm.content" placeholder="Please input content"/>
				</el-form-item>
			
			
			</el-form>
			
			<el-button @click="submitSupportOnline" style="margin-left: 180px;" type="primary">
				SUBMIT
			</el-button>
		</el-tab-pane>
	</el-tabs>
	<pc-footer/>
</template>

<script>
import {ref} from 'vue'
import {Delete} from '@element-plus/icons-vue'
import {floatToFixed2} from "@/utils/priceUtil"
import {getAllByPage} from "@/api/article/index";
import useCartCache from "@/store/cart";
import useCompareCache from "@/store/compare";
import {QueryCondition, queryWrapper} from "@/utils/queryWrapper";
import {useRouter, useRoute} from "vue-router"

import {freeCatelogCreate} from "@/api/freeCatelog/index";

import {supportOnlineCreate} from "@/api/supportOnline/index";

export default {
	name: 'Support',
	components: {
		Delete
	},
	props: {
		msg: String
	},
	data() {
		return {
			articles: [],
			activeName: "",
			router: useRouter(),
			freeCatelogForm: {
				fullName: "",
				address: "",
				city: "",
				state: "",
				country: "",
				tel: "",
				email: "",
				zip: "",
			},
			supportOnlineForm: {
				email: "",
				firstName: "",
				lastName: "",
				phone: "",
				subject: "",
				content: ""
			},
			freeCatelogRule: {
				// fullName:[
				//   {required: true, message: 'Please input your full name', trigger: 'blur'}
				// ],
				// address:[
				//   {required: true, message: 'Please input your address', trigger: 'blur'}
				// ],
				// city:[
				//   {required: true, message: 'Please input your city', trigger: 'blur'}
				// ],
				// state:[
				//   {required: true, message: 'Please input your state', trigger: 'blur'}
				// ],
				// country:[
				//   {required: true, message: 'Please input your country', trigger: 'blur'}
				// ],
				// tel:[
				//   {required: true, message: 'Please input your tel', trigger: 'blur'}
				// ],
				// email:[
				//   {required: true, message: 'Please input your email', trigger: 'blur'}
				// ]
			},
			supportOnlineRule: {
				email: [
					{required: true, message: 'Please input your email', trigger: 'blur'}
				],
				firstName: [
					{required: true, message: 'Please input your first name', trigger: 'blur'}
				],
				lastName: [
					{required: true, message: 'Please input your last name', trigger: 'blur'}
				],
				phone: [
					{required: true, message: 'Please input your phone', trigger: 'blur'}
				],
				subject: [
					{required: true, message: 'Please input your subject', trigger: 'blur'}
				],
				
			},
		}
		
	},
	watch: {
		async '$route'(to, from) {
			
			if (to.fullPath.split('=')[1] !== from.fullPath.split('=')[1]) {
				
				this.activeName = decodeURIComponent(decodeURIComponent(to.fullPath.split('=')[1] ? to.fullPath.split('=')[1] : this.articles[0]?.id))
				
				if (this.activeName === "Track My Order") {
					//this.activeName = this.articles[0]?.id
					//await this.router.replace({query: {type: this.articles[0]?.id}});
					await this.router.push({path: "/pages/orderQuery"});
				}
			}
		}
	},
	async created() {
		await this.getArticle();
		
		const type = this.$route.fullPath.split('=')[1];
		if (type) {
			this.activeName = type
			if (this.activeName === "Track My Order") {
				await this.router.push({path: "/pages/orderQuery"});
			}
		}
	},
	methods: {
		handleClick(id) {
			console.log(id)
			this.router.replace({path: "/pages/support", query: {type: encodeURIComponent(id)}})
		},
		async getArticle() {
			const {data: {list}} = await getAllByPage({
				page: 1,
				pageSize: 12,
				conditions: []
			})
			this.articles = list
		},
		submitFreeCatelog() {
			this.$refs["freeCatelogForm"].validate(async (valid) => {
				if (valid) {
					await freeCatelogCreate(this.freeCatelogForm);
					this.$message({
						message: 'Submit success!',
						type: 'success'
					});
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		async submitSupportOnline() {
			this.$refs["supportOnlineForm"].validate(async (valid) => {
				if (valid) {
					await supportOnlineCreate(this.supportOnlineForm);
					this.$message({
						message: 'Submit success!',
						type: 'success'
					});
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-popper.is-dark2 {
	background: #e35959;
	border: 1px solid #e35959;
	margin-left: 10px;
	vertical-align: middle;
	width: 22px;
	height: 12px;
}

.el-popper.is-dark2 .el-popper__arrow::before {
	background: #e35959;
	border: 1px solid #e35959;
}

.el-popover.el-popover.cc {
	padding: 0;
}

.el-tabs__item {
	font-size: 24px !important;
}

#tab-first {
	margin-bottom: 10px;
}

a:link {
	font-size: 14px;
	text-decoration: none;
	color: #757474;
}

.tabs-container ::v-deep(.el-tabs__header) {
	margin-right: 100px;
}


</style>
